import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Grid } from "../components/atoms/box";
import { IconLinkedIn } from "../components/atoms/icon";
import { Heading, Paragraph, Text } from "../components/atoms/typography";
import { Theme } from "../components/core/app.theme";
import { Horizontal } from "../components/gls/horizontal";
import { StretchSpacer, VerticalSpacer } from "../components/gls/spacer";
import { Vertical } from "../components/gls/vertical";
import { Page } from "../components/templates/page/page";
import { Section } from "../components/templates/page/section/section";
import { SectionHero, SectionHeroImageCss } from "../components/templates/page/section/section-hero";
import { SectionInfo, SectionInfoText } from "../components/templates/page/section/section-info";
import { SectionIntroHeading, SectionIntroLayout } from "../components/templates/page/section/section-intro";

const TeamPage = () => (
  <Page title={"Our team"}>
    <SectionHero
      image={
        <StaticImage
          src={`../assets/images/nature/ranch.png`}
          style={SectionHeroImageCss}
          placeholder="dominantColor"
          alt=""
        />
      }
      title={"Creating a hydrogen future"}
    />
    <Section>
      <SectionIntroLayout
        left={
          <Vertical>
            <SectionIntroHeading>Our Team</SectionIntroHeading>
          </Vertical>
        }
        right={
          <Vertical>
            <Paragraph maxWidth={450}>
              H2FA has brought together a project development team
              with expertise in engineering and regulatory management of some Australia’s leading hydrogen projects and refuelling installations.
            </Paragraph>
          </Vertical>
        }
      />
    </Section>
    <SectionInfo>
      <SectionInfoText>
        With a wealth of corporate, digital and green technology experience, H2FA is proud to implement Australia’s
        first integrated modular hydrogen production and refuelling operation
      </SectionInfoText>
    </SectionInfo>
    <Section>
      <Grid gridRowGap={"xl"} gridTemplateColumns={{ sm: "1fr", lg: "1fr 1fr" }} gridColumnGap={{ lg: "3xl" }}>
        <Individual
          name="Francesco Ceravolo"
          title="Managing Director"
          description={[
            "With a background in the scaling of large energy projects and an understanding of the needs of large energy users, Francesco is well placed to lead H2FA’s team. ",
            "A mechanical engineer (RMIT) with an MBA in Finance (Manchester), Francesco began his career in energy with Pirelli, as part of the engineering team which commissioned the Basslink Project, before working as a project engineer for Australian Energy Company (now Victorian Hydrogen Industries).",
            "More recently, Francesco has worked as a private consultant analysing energy policy and its implications for both organisations and private investors.",
          ]}
          linkedIn={"https://www.linkedin.com/in/francescoceravolo/"}
        >
          <StaticImage
            src={`../assets/images/team/francesco.jpg`}
            objectFit={"contain"}
            placeholder="blurred"
            alt="Francesco Ceravolo"
          />
        </Individual>

        <Individual
          name="Arthur Lucas Rybka"
          title="Project Engineering"
          description="A Masters of Engineering and Bachelor of Electrical Engineering (Monash University). Arthur has built a career as a senior consultant in sectors as diverse as pharmaceutical, biomedical, defence, utilities, renewable energy, automotive and aerospace. He has gained considerable knowledge in hydrogen specific regulatory (both state and federal) compliance and currently practices as a subject matter expert in the field. Having delivered multiple projects in Australia (including Australia’s first hydrogen fast refueler, storage and generation  station for Toyota in Altona), Asia and Europe, he is uniquely placed to lead H2FA as its technical lead."
          linkedIn={"https://au.linkedin.com/in/arthur-rybka-88433b65"}
        >
          <StaticImage
            src={`../assets/images/team/arthur.jpg`}
            objectFit={"contain"}
            placeholder="blurred"
            alt="Arthur Lucas Rybka"
          />
        </Individual>
        <Individual
          name="Angelo Raffaele Demasi"
          title="Digital Strategy"
          description="A global expert in emerging technology and digital business building, Angelo brings to H2FA excellence in C-Suite governance and leadership in early-stage growth environments and public listed companies globally. As a qualified systems and software engineer (RMIT), he has built an international career over more than 20 years, spanning executive leadership, board directorship, management consulting, business innovation, corporate strategy, digital transformation and project delivery."
          linkedIn={"https://www.linkedin.com/in/angelodemasi"}
        >
          <StaticImage
            src={`../assets/images/team/angelo.jpg`}
            objectFit={"contain"}
            placeholder="blurred"
            alt="Angelo Raffaele Demasi"
          />
        </Individual>
        <Individual
          name="Dr Rebecca Yee"
          title="Education"
          description="A PhD in Chemical Engineering (Monash University), Rebecca has been developing and advocating for circular systems and sustainable fuels for over a decade. She is a co-founder of Biofuel Innovations, builders of a 2000L biodiesel pilot plant in Dandenong, and is involved in organisations such as Future Fuels CRC, an initiative supported by The University of Melbourne where Rebecca is currently a research fellow."
          linkedIn={"https://www.linkedin.com/in/rebecca-yee-9193b353/"}
        >
          <StaticImage
            src={`../assets/images/team/rebecca.jpg`}
            objectFit={"contain"}
            placeholder="blurred"
            alt="Dr Rebecca Yee"
          />
        </Individual>


      </Grid>
    </Section>
    <Section backgroundColor={"white"} pb={"4xl"}>
      <Heading as={"h2"} fontSize={"md"} fontWeight={"book"} fontFamily={"monument"}>
        Key Project Advisers
      </Heading>
      <VerticalSpacer space={"xl"} />
      <Grid
        gridRowGap={{ sm: "xl", lg: "3xl" }}
        gridTemplateColumns={{ sm: "1fr", lg: "1fr 1fr" }}
        gridColumnGap={{ lg: "3xl" }}
      >
        <Advisor
          name="Site Design and Project Management"
          description="Having been involved with more than 1200 service stations and truck stop projects, TfA is well versed in the safe storage and handling of flammable gases.  National experts in the field, TfA is responsible for the safe planning of H2FA’s hydrogen project."
        >
          <Box minH={100}>
            <StaticImage
              src={`../assets/images/advisors/tfa-project-group.png`}
              objectFit={"fill"}
              placeholder="blurred"
              style={{
                maxWidth: 160,
              }}
              alt="TFA Group"
            />
          </Box>
        </Advisor>
        <Advisor name="Corporate Law" description="Australia’s largest law firm assists H2FA with corporate advice.">
          <Box minH={100}>
            <StaticImage
              src={`../assets/images/advisors/minter-ellison.png`}
              objectFit={"fill"}
              placeholder="blurred"
              style={{
                maxWidth: 160,
              }}
              alt="TFA Group"
            />
          </Box>
        </Advisor>
        <Advisor
          name="Energy Law and Planning"
          description="Market leader in the Australian renewable energy sector, HWL Ebsworth assists H2FA with Planning and Energy Law matters."
        >
          <StaticImage
            src={`../assets/images/advisors/hwl-lawyers.png`}
            objectFit={"fill"}
            placeholder="blurred"
            style={{
              maxWidth: 200,
            }}
            alt="HWL Lawyers"
          />
        </Advisor>
      </Grid>
    </Section>
  </Page>
);

const Individual = (props: {
  name: string;
  title: string;
  description: string | string[];
  linkedIn: string;
  children: React.ReactNode;
}) => (
  <Vertical>
    {props.children}
    <Horizontal>
      <Vertical space={"xs"}>
        <Text fontSize={"md"} fontWeight={"bold"}>
          {props.name}
        </Text>
        <Text fontWeight={"bold"} textTransform={"uppercase"}>
          {props.title}
        </Text>
      </Vertical>
      <StretchSpacer />
      <IconLinkedIn
        color={"primary"}
        action={{ as: "a", href: props.linkedIn, target: "blank", color: Theme.color("primary400") }}
      />
    </Horizontal>
    {Array.isArray(props.description) ? (
      <Vertical space={"xs"}>
        {props.description.map((it) => (
          <Text>{it}</Text>
        ))}
      </Vertical>
    ) : (
      <Text>{props.description}</Text>
    )}
  </Vertical>
);

const Advisor = (props: {
  name: string;
  description: string;
  children: React.ReactNode;
  colorScheme?: Theme.ColorScheme;
}) => (
  <Vertical>
    {props.children}
    <Text fontSize={"md"} fontWeight={"bold"}>
      {props.name}
    </Text>
    <Text>{props.description}</Text>
  </Vertical>
);

export default TeamPage;
